import React, { useState, useEffect } from "react";
import {
  useParams,
  useLoaderData,
  useNavigate,
  Navigate,
} from "react-router-dom";
// import { Loading } from '../common/Loading';
// import CollectionAssetPage from './components/CollectionAssetPage';

//Templates 
import { CollectionAsset } from "../store/api";
import { chooseAssetTemplate } from "../utils/chooseTemplate";
import {
  useContactUsFeature,
  useLeadFeature,
  useShareFeature,
} from "../utils/hooks";
import { emailTemplateInstanceID } from "../utils/constants";
import { useSelector } from "react-redux";
import FallbackSocial from "../components/shared/FallbackSocial";
import LocationTracker from "../utils/LocationTracker";

export const CollectionRouteAssetPath = () => {

  const { collectionURL, contentType, ID } = useParams();
  const assetLoaderData = useLoaderData();
  const { isLeadSent } = useSelector((state) => state.state);
  const [features, setFeatures] = useState({
    isContactUsActive: false,
    isShareActive: false,
    isLeadActive: {
      from:
        isLeadSent === false &&
          assetLoaderData?.data?.settings?.isLeadsCaptureFormEnabled === "Yes"
          ? "PAGE"
          : "AFTER-SHARE",
      value:
        isLeadSent === false &&
          assetLoaderData?.data?.settings?.isLeadsCaptureFormEnabled === "Yes"
          ? true
          : false,
    },
  });
  let typeNo;

  useEffect(() => {
    setAccentColorsFn();

  }, []);

  const setAccentColorsFn = () => {
    const root = document.documentElement;
    root.style.setProperty(
      "--primary-color",
      assetLoaderData?.data?.branding?.accentColors.primary
    );
    root.style.setProperty(
      "--bs-primary",
      assetLoaderData?.data?.branding?.accentColors.primary
    );
    root.style.setProperty(
      "--secondary-color",
      assetLoaderData?.data?.branding?.accentColors.secondary
    );
    if (assetLoaderData?.data?.asset_type !== "products") {
      document.title = `${assetLoaderData?.data?.asset_details?.title}`;
    } else {
      document.title = `${assetLoaderData?.data?.product[0]?.product_title}`;
    }
  };

  const { ShareFeatureDesign } = useShareFeature({
    isActive: features?.isShareActive,
    changeState: setFeatures,
    exhibitorID: assetLoaderData?.data?.exhibitor_id,
    emailConfig: {
      emailInstanceID: emailTemplateInstanceID?.ASSET,
      sourceID: ID,
      contentType: contentType,
    },
  });
  const { ContactUsFeatureDesign } = useContactUsFeature({
    exhibitorID: assetLoaderData?.data?.exhibitor_id,
    changeState: setFeatures,
    isActive: features?.isContactUsActive,
  });
  const { LeadFeatureDesign } = useLeadFeature({
    exhibitorID: assetLoaderData?.data?.exhibitor_id,
    changeState: setFeatures,
    isActive: features?.isLeadActive?.value,
  });


  switch (assetLoaderData?.data?.asset_type) {
    case "documents":
      typeNo = 11;
      break;
    case "images":
      typeNo = 22;
      break;
    case "products":
      typeNo = 7;
      break;
    case "links":
      typeNo = 23;
      break;
    case "3D":
      typeNo = 24;
      break;
    case "pages":
      typeNo = 26;
      break;
    case "videos":
      typeNo = 12;
      break;
    case "audios":
      typeNo = 25;
      break;

    default:
  }
  if (assetLoaderData?.data?.status == "failed") {
    return <Navigate to="/404" replace={true} />;
  }
  const flipBook = 'No'
  return (
    <>
      {chooseAssetTemplate(
        flipBook,
        assetLoaderData?.data?.templateName,
        assetLoaderData?.data?.asset_type,
        assetLoaderData?.data,
        setFeatures,
        {
          isContactUsFormEnabled:
            assetLoaderData?.data?.settings?.isContactUsFormEnabled,
          isShareFormEnabled:
            assetLoaderData?.data?.settings?.isShareFormEnabled,
          isSocialSharingButtonEnabled:
            assetLoaderData?.data?.settings?.isSocialSharingButtonEnabled,
            isPoweredByLogoEnabled: assetLoaderData?.data?.settings?.isPoweredByLogoEnabled,
        },
        { value: true, url: collectionURL }
      )}
      <ShareFeatureDesign />
      <ContactUsFeatureDesign />
      <LeadFeatureDesign />
      <FallbackSocial />
      <LocationTracker
        exhibitorID={assetLoaderData?.data?.exhibitor_id}
        source="assets"
        typeNumber={typeNo}
        assetORCollectionID={assetLoaderData?.data?.asset_id}
      />
    </>
  );
};

export const Loader = ({ params }) => {
  const { ID, collectionURL } = params;
  return CollectionAsset({
    asset_id: ID,
    collection_unique_url: collectionURL,
  });
};
