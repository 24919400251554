import { useEffect, useState } from "react";
import { ViewCount,boothvisitor } from "../store/api";
import axios from "axios";
import { updateState } from "../store/slice/state";
import { useDispatch } from "react-redux";

const LocationTracker = ({ exhibitorID, typeNumber,source,assetORCollectionID }) => {
  //console.log(assetORCollectionID,"idididi");
  const dispatch = useDispatch();
  const [pos_lat_lon, setPosLatLon] = useState("");

  let Details_str = window.navigator.appVersion;
  let Device_info = Details_str.substring(
    Details_str.indexOf("(") + 1,
    Details_str.indexOf(")")
  );
  let Device_info_extract = Device_info.split(";");
  let isMobile = {
    Android: function () {
      return navigator.userAgent.match(/Android/i);
    },
    BlackBerry: function () {
      return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: function () {
      return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    Opera: function () {
      return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows: function () {
      return (
        navigator.userAgent.match(/IEMobile/i) ||
        navigator.userAgent.match(/WPDesktop/i)
      );
    },
    any: function () {
      return (
        isMobile.Android() ||
        isMobile.BlackBerry() ||
        isMobile.iOS() ||
        isMobile.Opera() ||
        isMobile.Windows()
      );
    },
  };
  function osDetect() {
    let osversion;
    if (Device_info.toLowerCase().includes("mac")) {
      osversion = "Mac";
    } else if (Device_info.toLowerCase().includes("android")) {
      osversion = "Android";
    } else if (Device_info.toLowerCase().includes("ios")) {
      osversion = "ios";
    } else if (!isMobile.any()) {
      osversion = Device_info_extract[0];
    }
    return osversion;
  }

  function browserDetect() {
    let browserversion;
    let Browser_info = Details_str.substring(
      Details_str.lastIndexOf(")") + 1,
      Details_str.length
    ).trim(" ");
    let Browser_info_extract = Browser_info.split(" ");
    if (Device_info.toLowerCase().includes("android")) {
      browserversion = Browser_info_extract[0].split("/")[0];
    } else if (Device_info.toLowerCase().includes("iphone")) {
      if (Browser_info_extract[0].toLowerCase().includes("crios")) {
        browserversion = Browser_info_extract[0].split("/")[0];
      } else {
        browserversion =
          Browser_info_extract[Browser_info_extract.length - 1].split("/")[0];
      }
    } else if (!isMobile.any()) {
      browserversion = "unknown";
    }
    return browserversion;
  }

  function handsetDetect() {
    let handsetversion;
    if (Device_info.toLowerCase().includes("android")) {
      handsetversion = Device_info_extract[Device_info_extract.length - 1];
    } else if (Device_info.toLowerCase().includes("iphone")) {
      handsetversion = "iPhone";
    } else if (!isMobile.any()) {
      handsetversion = "unknown";
    }
    return handsetversion;
  }

  let details = {
    Location_details: {
      city: "unknown",
      city_district: "unknown",
      country: "unknown",
      country_code: "unknown",
      neighbourhood: "unknown",
      postcode: "unknown",
      state: "unknown",
      state_district: "unknown",
      suburb: "unknown",
    },
    Longitude: "unknown",
    Latitude: "unknown",
    Browser_CodeName: window.navigator.appCodeName,
    Browser_Name: window.navigator.appName,
    Cookies_Enabled: window.navigator.cookieEnabled,
    Browser_Version: window.navigator.appVersion,
    Browser_Language: window.navigator.language,
    Browser_Online: window.navigator.onLine,
    Platform: window.navigator.platform,
    Device_name: isMobile.any() ? "Mobile" : "PC",
    Handset: handsetDetect(),
    OS: osDetect(),
    Browser: browserDetect(),
  };

  const allowposition = (position) => {
    setPosLatLon(position.coords.latitude + "," + position.coords.longitude);
  };
  const blockposition = (err) => {
    _dummy_fn("", "");
  };
  let isQrScan;
  if (new URLSearchParams(window.location.search).has("qrscan")) {
    isQrScan = new URLSearchParams(window.location.search).get("qrscan");
    isQrScan = String(isQrScan);
    // console.log(isQrScan,"isqrscanValue");
  } else {
    isQrScan = "0";
    //console.log(isQrScan,"isqrscanNovalue");
  }

  const _dummy_fn = (lat, lon) => {
   // console.log(lat,lon,"lat");
    if (lat !== "" && lon !== "") {
      axios(
        "https://nominatim.openstreetmap.org/reverse?format=json&lat=" +
          lat +
          "&lon=" +
          lon,
        {
          method: "GET",
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        }
      ).then((response) => {
        details.Location_details.city =
          response.data.address.length != 0
            ? response.data.address.city
            : "unknown";
        details.Location_details.city_district =
          response.data.address.length != 0
            ? response.data.address.city_district
            : "unknown";
        details.Location_details.country =
          response.data.address.length != 0
            ? response.data.address.country
            : "unknown";
        details.Location_details.country_code =
          response.data.address.length != 0
            ? response.data.address.country_code
            : "unknown";
        details.Location_details.neighbourhood =
          response.data.address.length != 0
            ? response.data.address.neighbourhood
            : "unknown";
        details.Location_details.postcode =
          response.data.address.length != 0
            ? response.data.address.postcode
            : "unknown";
        details.Location_details.state =
          response.data.address.length != 0
            ? response.data.address.state
            : "unknown";
        details.Location_details.state_district =
          response.data.address.length != 0
            ? response.data.address.state_district
            : "unknown";
        details.Location_details.suburb =
          response.data.address.length != 0
            ? response.data.address.suburb
            : "unknown";
        details.Latitude = lat;
        details.Longitude = lon;

        ViewCount({
          // QR code scan code sending API done only once while loading the page initially
          records: JSON.stringify({
            records: [
              {
                value: {
                  ...details,
                  page_url: window.location.href,
                  exhibitor_id: exhibitorID,
                  campaign_id:
                    typeof new URLSearchParams(window.location.search).get(
                      "campaign_id"
                    ) !== "undefined" &&
                    new URLSearchParams(window.location.search).get(
                      "campaign_id"
                    ) !== null
                      ? new URLSearchParams(window.location.search).get(
                          "campaign_id"
                        )
                      : "unknown",
                  type: typeNumber,
                  qr_scan: isQrScan,
                  // qr_scan: new URLSearchParams(window.location.search).has(
                  //   "qrscan"
                  // )
                  //   ? new URLSearchParams(window.location.search).get("qrscan")
                  //   : 0,
                },
              },
            ],
          }),
        });
        dispatch(
          updateState({ agentdetails: details, locationCollected: true })
        );
        analytics();
      });
    } else {
      ViewCount({
        // QR code scan code sending API done only once while loading the page initially
        records: JSON.stringify({
          records: [
            {
              value: {
                ...details,
                page_url: window.location.href,
                exhibitor_id: exhibitorID,
                campaign_id:
                  typeof new URLSearchParams(window.location.search).get(
                    "campaign_id"
                  ) !== "undefined" &&
                  new URLSearchParams(window.location.search).get(
                    "campaign_id"
                  ) !== null
                    ? new URLSearchParams(window.location.search).get(
                        "campaign_id"
                      )
                    : "unknown",
                type: typeNumber,
                qr_scan: isQrScan,
                // qr_scan: new URLSearchParams(window.location.search).has(
                //   "qrscan"
                // )
                //   ? new URLSearchParams(window.location.search).get("qrscan")
                //   : 0,
              },
            },
          ],
        }),
      });
      dispatch(updateState({ agentdetails: details, locationCollected: true }));
      analytics();
    }
  
   
    function analytics(click_event_name) {
     // console.log(details,"details");
      //Analytics data taken from state that has been collected from QRCode.js page  and
      //then added along with other datas and sent to mongoDB.
      let dataAnalytics = {
        source: source,
        encoded_data: {
          exhibitor_id: exhibitorID,
          campaign_id:
            typeof new URLSearchParams(window.location.search).get(
              "campaign_id"
            ) !== "undefined" &&
            new URLSearchParams(window.location.search).get("campaign_id") !==
              null
              ? new URLSearchParams(window.location.search).get("campaign_id")
              : "unknown",
          tour_code: "unknown",
          agentdetails:{...details},
          data_id: assetORCollectionID,
          click_event_name: null,
          type: typeNumber,
          page_url: window.location.href,
        },    
      };
  
      boothvisitor(dataAnalytics).then((res) => {
        // console.log(res);
      });
    }
  };
 
  useEffect(() => {
    if (pos_lat_lon !== "") {
      details.Latitude = pos_lat_lon.split(",")[0];
      details.Longitude = pos_lat_lon.split(",")[1];
      _dummy_fn(pos_lat_lon.split(",")[0], pos_lat_lon.split(",")[1]);
    }
  }, [pos_lat_lon]);

  useEffect(() => {
    window.navigator.geolocation.getCurrentPosition(
      allowposition,
      blockposition
    );
  }, []);

  return null;
};

export default LocationTracker;
  