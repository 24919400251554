import { Template1Video } from "../components/layoutTypes/webpage/template1/Video1/Template1Video";
import { Template1Collection } from "../components/layoutTypes/webpage/template1/Collection1/Template1Collection";
import { Template1Product } from "../components/layoutTypes/webpage/template1/Product1/Template1Product";
import { Template1Document } from "../components/layoutTypes/webpage/template1/Document1/Template1Document";
import { Template1Audio } from "../components/layoutTypes/webpage/template1/Audio1/Template1Audio";
import { Template1Link } from "../components/layoutTypes/webpage/template1/Link1/Template1Link";
import { Template1Page } from "../components/layoutTypes/webpage/template1/Page1/Template1Page";
import { Template1ThreeD } from "../components/layoutTypes/webpage/template1/ThreeD1/Template1ThreeD";
import { Template1Image } from "../components/layoutTypes/webpage/template1/Image1/Template1Image";
import { Template2Collection } from "../components/layoutTypes/webpage/template2/Collection2/Template2Collection";
import { Template2Video } from "../components/layoutTypes/webpage/template2/Video2/Template2Video";
import { Template2Product } from "../components/layoutTypes/webpage/template2/Product2/Template2Product";
import { Template2Image } from "../components/layoutTypes/webpage/template2/Image2/Template2Image";
import { Template2ThreeD } from "../components/layoutTypes/webpage/template2/ThreeD2/Template2ThreeD";
import { Template2Page } from "../components/layoutTypes/webpage/template2/Page2/Template2Page";
import { Template2Link } from "../components/layoutTypes/webpage/template2/Link2/Template2Link";
import { Template2Document } from "../components/layoutTypes/webpage/template2/Document2/Template2Document";
import { Template2Audio } from "../components/layoutTypes/webpage/template2/Audio2/Template2Audio";
import { Template3GuideBook } from "../components/layoutTypes/guidebook/template3/Template3GuideBook";

import { Template4Video } from "../components/layoutTypes/photo/template4/Video4/Template4Video";
import { Template4Collection } from "../components/layoutTypes/photo/template4/Collection4/Template4Collection";
import { Template4Product } from "../components/layoutTypes/photo/template4/Product4/Template4Product";
import { Template4Document } from "../components/layoutTypes/photo/template4/Document4/Template4Document";
import { Template4Audio } from "../components/layoutTypes/photo/template4/Audio4/Template4Audio";
import { Template4Link } from "../components/layoutTypes/photo/template4/Link4/Template4Link";
import { Template4Page } from "../components/layoutTypes/photo/template4/Page4/Template4Page";
import { Template4ThreeD } from "../components/layoutTypes/photo/template4/ThreeD4/Template4ThreeD";
import { Template4Image } from "../components/layoutTypes/photo/template4/Image4/Template4Image";
import { Template5VideoAlbum } from "../components/layoutTypes/video/template5/Template5VideoAlbum";

import { Template6Collection } from "../components/layoutTypes/presentation/template6/Collection6/Template6Collection";

import FlipBook from "../components/layoutTypes/flipBook/FlipBook";
export const chooseCollectionTemplate = (
  templateType,
  templateName,
  data,
  setFeatures,
  settings
) => {
  switch (templateType) {
    case "webpage": {
      switch (templateName) {
        case "TEMPLATE-1":
          return (
            <Template1Collection
              data={data}
              setFeatures={setFeatures}
              settings={settings}
            />
          );

        case "TEMPLATE-2":
          return (
            <Template2Collection
              data={data}
              setFeatures={setFeatures}
              settings={settings}
            />
          );
        default:
          return <Template1Collection />;
      }
    }

    case "guide": {
      switch (templateName) {
        case "TEMPLATE-3":
          return (
            <Template3GuideBook
              data={data}
              setFeatures={setFeatures}
              settings={settings}
            />
          );
        default:
          return (
            <Template3GuideBook
              data={data}
              setFeatures={setFeatures}
              settings={settings}
            />
          );
      }
    }
    case "photo": {
      switch (templateName) {
        case "TEMPLATE-4":
          return (
            <Template4Collection
              data={data}
              setFeatures={setFeatures}
              settings={settings}
            />
          );
        default:
          return (
            <Template4Collection
              data={data}
              setFeatures={setFeatures}
              settings={settings}
            />
          );
      }
    }
    case "video": {
      switch (templateName) {
        case "TEMPLATE-5":
          return (
            <Template5VideoAlbum
              data={data}
              setFeatures={setFeatures}
              settings={settings}
            />
          );
        default:
          return (
            <Template5VideoAlbum
              data={data}
              setFeatures={setFeatures}
              settings={settings}
            />
          );
      }
    }
    case "audioalbum": {
      switch (templateName) {
        default:
          return null;
      }
    }

    case "presentation": {
      switch (templateName) {
        case "TEMPLATE-6":
          return (
            <Template6Collection
              data={data}
              setFeatures={setFeatures}
              settings={settings}
            />
          );
        default:
          return (
            <Template6Collection
              data={data}
              setFeatures={setFeatures}
              settings={settings}
            />
          );
      }
    }
    default:
      return null;
  }
};

export const chooseAssetTemplate = (
  flipBook,
  templateName,
  assetType,
  data,
  setFeatures,
  settings,
  goBack
) => {
  switch (templateName) {
    case "TEMPLATE-1": {
      switch (assetType) {
        case "videos":
          return (
            <Template1Video
              data={data}
              setFeatures={setFeatures}
              settings={settings}
              goBack={goBack}
            />
          );
        case "products":
          return (
            <Template1Product
              data={data}
              setFeatures={setFeatures}
              settings={settings}
              goBack={goBack}
            />
          );
        case "audios":
          return (
            <Template1Audio
              data={data}
              setFeatures={setFeatures}
              settings={settings}
              goBack={goBack}
            />
          );
        case "documents":
          switch (flipBook) {
            case "Yes":
              return (
                <FlipBook
                  data={data}
                  setFeatures={setFeatures}
                  settings={settings}
                  goBack={goBack}
                />
              );
            case "No":
              return (
                <Template1Document
                  data={data}
                  setFeatures={setFeatures}
                  settings={settings}
                  goBack={goBack}
                />
              );
          }

        case "links":
          return (
            <Template1Link
              data={data}
              setFeatures={setFeatures}
              settings={settings}
              goBack={goBack}
            />
          );
        case "pages":
          return (
            <Template1Page
              data={data}
              setFeatures={setFeatures}
              settings={settings}
              goBack={goBack}
            />
          );
        case "3D":
          return (
            <Template1ThreeD
              data={data}
              setFeatures={setFeatures}
              settings={settings}
              goBack={goBack}
            />
          );
        case "images":
          return (
            <Template1Image
              data={data}
              setFeatures={setFeatures}
              settings={settings}
              goBack={goBack}
            />
          );
        default:
          return null;
      }
    }
    case "TEMPLATE-2": {
      switch (assetType) {
        case "videos":
          return (
            <Template2Video
              data={data}
              setFeatures={setFeatures}
              settings={settings}
              goBack={goBack}
            />
          );
        case "products":
          return (
            <Template2Product
              data={data}
              setFeatures={setFeatures}
              settings={settings}
              goBack={goBack}
            />
          );
        case "audios":
          return (
            <Template2Audio
              data={data}
              setFeatures={setFeatures}
              settings={settings}
              goBack={goBack}
            />
          );
        case "documents":
          switch (flipBook) {
            case "Yes":
              return (
                <FlipBook
                  data={data}
                  setFeatures={setFeatures}
                  settings={settings}
                  goBack={goBack}
                />
              );
            case "No":
              return (
                <Template2Document
                  data={data}
                  setFeatures={setFeatures}
                  settings={settings}
                  goBack={goBack}
                />
              );
          }
        case "links":
          return (
            <Template2Link
              data={data}
              setFeatures={setFeatures}
              settings={settings}
              goBack={goBack}
            />
          );
        case "pages":
          return (
            <Template2Page
              data={data}
              setFeatures={setFeatures}
              settings={settings}
              goBack={goBack}
            />
          );
        case "3D":
          return (
            <Template2ThreeD
              data={data}
              setFeatures={setFeatures}
              settings={settings}
              goBack={goBack}
            />
          );
        case "images":
          return (
            <Template2Image
              data={data}
              setFeatures={setFeatures}
              settings={settings}
              goBack={goBack}
            />
          );
        default:
          return null;
      }
    }
    case "TEMPLATE-3": {
      switch (assetType) {
        case "videos":
          return (
            <Template4Video
              data={data}
              setFeatures={setFeatures}
              settings={settings}
              goBack={goBack}
            />
          );
        case "products":
          return (
            <Template4Product
              data={data}
              setFeatures={setFeatures}
              settings={settings}
              goBack={goBack}
            />
          );
        case "audios":
          return (
            <Template4Audio
              data={data}
              setFeatures={setFeatures}
              settings={settings}
              goBack={goBack}
            />
          );
        case "documents":
          switch (flipBook) {
            case "Yes":
              return (
                <FlipBook
                  data={data}
                  setFeatures={setFeatures}
                  settings={settings}
                  goBack={goBack}
                />
              );
            case "No":
              return (
                <Template4Document
                  data={data}
                  setFeatures={setFeatures}
                  settings={settings}
                  goBack={goBack}
                />
              );
          }
        case "links":
          return (
            <Template4Link
              data={data}
              setFeatures={setFeatures}
              settings={settings}
              goBack={goBack}
            />
          );
        case "pages":
          return (
            <Template4Page
              data={data}
              setFeatures={setFeatures}
              settings={settings}
              goBack={goBack}
            />
          );
        case "3D":
          return (
            <Template4ThreeD
              data={data}
              setFeatures={setFeatures}
              settings={settings}
              goBack={goBack}
            />
          );
        case "images":
          return (
            <Template4Image
              data={data}
              setFeatures={setFeatures}
              settings={settings}
              goBack={goBack}
            />
          );
        default:
          return null;
      }
    }
    default:
      return null;
  }
};
