import { boothvisitor } from "../store/api";
import pdf from "../../src/assets/file.1a0303bd.png";
import Doc from "../../src/assets/doc.a2c9c4a2.png";
import veeLogo from "../../src/assets/veelogo.png";

export const DownloadDocument = (url, name) => {
  const link = document.createElement("a");
  link.download = name;
  link.href = url;
  link.target = "_blank";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

  return null;
};

export const DownloadImage = async (url) => {
  let a = document.createElement("a");
  a.href = url;
  document.body.appendChild(a);
  a.click();
  a.remove();
};
export const DownloadThreeD =async (url) => {
  let a = document.createElement("a");
  a.href = url;
  document.body.appendChild(a);
  a.click();
  a.remove();
};
export const DownloadAudios=async (url) => {
  let a = document.createElement("a");
  a.href = url;
  document.body.appendChild(a);
  a.click();
  a.remove();
};
export const webanalytics_track = ({
  exhibitorID,
  campaignID,
  agentdetails,
  ID,
  clickEventName,
}) => {
  const payload = {
    source: "collections-micro-pages",
    encoded_data: {
      exhibitor_id: exhibitorID,
      campaign_id: campaignID !== null ? campaignID : "unknown",
      tour_code: "unknown",
      agentdetails: agentdetails,
      data_id: ID,
      click_event_name: clickEventName,
      page_url: window.location.href,
    },
  };
  boothvisitor(payload).then((response) => {});
};



export const getThumbnailUrl = (item) => {

  if (item.identifiedAs === "Videos") {
    const videoIdMatch = item.source.match(/(?:v=|\/)([0-9A-Za-z_-]{11}).*/);
    if (videoIdMatch) {
      const videoId = videoIdMatch[1];
      return `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`;
    }
  } else if (item.identifiedAs === 'Images' || item.identifiedAs === 'Products') {
    return item.source;
  } else if (item.asset_name && item.asset_name.endsWith('.pdf')) {
    return pdf;
  } else if (item.asset_name && item.asset_name.endsWith('.docx')) {
    return Doc;
  }
  else if ( item.identifiedAs === 'Pages') {
    return veeLogo;
  } else if (item.identifiedAs === 'Links' ) { 
      return `https://t1.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=${encodeURIComponent(item.asset_name)}/&size=64`;
  } else {
    return item.thumbnailImageURL;
  }
};

