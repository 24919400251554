import React, { useState, useCallback, useEffect } from "react";
//react-icons
import { AiOutlineArrowRight } from "react-icons/ai";
import { useNavigate, useParams } from "react-router-dom";

import { useMasonryLayout, useWindowSize } from "../../../../../utils/hooks";

import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

// Image Viewer
import ImageViewer from "react-simple-image-viewer";

const Template4CollectionContainer = ({ data, selectedTab }) => {
  const { collectionURL } = useParams();
  const navigate = useNavigate();
  const { size } = useWindowSize();
  const { columnWiseData } = useMasonryLayout(
    columnNumber(),
    data[selectedTab]
  );
  function columnNumber() {
    let num;
    if (size?.width < 600) {
      num = 1;
    } else if (size?.width >= 600 && size?.width < 1000) {
      num = 2;
    } else if (size?.width >= 1000) {
      num = 3;
    }
    return num;
  }
  function goToIndividualAsset(contentType, ID) {
    navigate(`/${collectionURL}/${contentType}/${ID}`);
  }

  const [imgArray, setImgArray] = useState([]);
  const [otherAssets, setOtherAssets] = useState([]);
  const [imgPathArr, setImgPathArr] = useState([]);
  //Image Viewer
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const [columnsfetched, setColumnsfetched] = useState(null);

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };
  useEffect(() => {
    let emptyImgArray = new Array();
    let emptyImgPathArray = new Array();
    let otherAssetsArray = new Array();

    // Object.keys(columnWiseData).map((columnName, index) => {
    //   columnWiseData[columnName].map((item, index) => {
    //     if (item?.identifiedAs.toLowerCase() === "images") {
    //       emptyImgPathArray?.push(item?.thumbnailImageURL);
    //     }
    //   });
    //   setImgPathArr(emptyImgPathArray);

    //   //console.log(emptyImgPathArray,"images");
    // });

    //  let imgElements = document.querySelectorAll('[data-src="images"]');
    let cnt = document.querySelectorAll(".columns");
    setColumnsfetched(cnt);
    //console.log(cnt,"container");
    // console.log(imgElements[0].target,"imgsrcs");
    // imgElements.map((item)=>{
    //   emptyImgPathArray?.push( item?.target?.getAttribute('src'))
    // })
    // setImgPathArr(emptyImgPathArray)
    // data?.[selectedTab]?.map((item) => {
    //   if (item?.identifiedAs === "Images") {
    //     //console.log("img");
    //     emptyImgArray?.push(item);
    //    emptyImgPathArray?.push(item?.thumbnailImageURL);
    //   } else {
    //     otherAssetsArray?.push(item);
    //   }
    //   // console.log(emptyImgArray);
    //   setOtherAssets(otherAssetsArray);
    //   setImgArray(emptyImgArray);
    //   setImgPathArr(emptyImgPathArray);
    // });
  }, [selectedTab]);

  useEffect(() => {
    let emptyImgPathArray = new Array();
    if (columnsfetched !== null && columnsfetched.length !== 0) {
      columnsfetched.forEach((item, index) => {
        let spanElement = item.getElementsByClassName("items");

        for (let i = 0; i < spanElement?.length; i++) {
          emptyImgPathArray?.push(spanElement[i]?.dataset?.src);
        }
      });
     // console.log(emptyImgPathArray, "imgArray");
      setImgPathArr(emptyImgPathArray);
    }
  }, [columnsfetched]);

  return (
    <>
      {/* <div className="container-overall">
        {imgArray?.map((src, index) => (
          <div key={index} className="temp1-product-tab-content-images-item">
            <img
              src={src?.thumbnailImageURL}
              onClick={() => openImageViewer(index)}
              width="300"
              key={index}
              alt={src?.imageTitle}
            />
            <div className="temp4-item-body">
              <p className="temp4-item-name">
                {src?.title.length > 70
                  ? `${src.title.substring(0, 70)}...`
                  : src.title}
              </p>
            </div>
          </div>
        ))}
        {otherAssets?.map((item, index) => {
          return (
            <div
              key={index}
              className="temp1-product-tab-content-images-item"
              onClick={() =>
                goToIndividualAsset(item?.identifiedAs.toLowerCase(), item?._id)
              }
            >
              <LazyLoadImage
                alt=""
                effect="blur"
                src={item.thumbnailImageURL}
              />

              <div className="temp4-item-body">
                <p className="temp4-item-name">
                  {item.title.length > 70
                    ? `${item.title.substring(0, 70)}...`
                    : item.title}
                </p>
              </div>
            </div>
          );
        })}
        {isViewerOpen && (
          <ImageViewer
            src={imgPathArr}
            currentIndex={currentImage}
            disableScroll={false}
            closeOnClickOutside={true}
            onClose={closeImageViewer}
          />
        )}
      </div> */}
      <div className="container-overall">
        {Object.keys(columnWiseData).map((columnName, index) => {
          return (
            <div key={index} className="columns">
              {columnWiseData[columnName].map((item, index) => {
                return (
                  <div
                    key={index}
                    className="items"
                    data-src={item?.thumbnailImageURL}
                    onClick={
                      item?.identifiedAs?.toLowerCase() === "images"
                        ? () =>
                            openImageViewer(
                              imgPathArr.indexOf(item?.thumbnailImageURL)
                            )
                        : () =>
                            goToIndividualAsset(
                              item?.identifiedAs.toLowerCase(),
                              item?._id
                            )
                    }
                  >
                    <LazyLoadImage
                      alt=""
                      effect="blur"
                      style={{ width: "100%", height: "100%" }}
                      src={item?.thumbnailImageURL}
                    />

                    <div>
                      {" "}
                      <p>
                        {item.title.length > 70
                          ? `${item.title.substring(0, 70)}...`
                          : item.title}
                      </p>
                      {item?.identifiedAs?.toLowerCase() !== "images" ? (
                        <span>
                          <AiOutlineArrowRight />{" "}
                        </span>
                      ) : null}
                    </div>
                  </div>
                );
              })}
            </div>
          );
        })}
        {isViewerOpen && (
          <ImageViewer
            src={imgPathArr}
            currentIndex={currentImage}
            disableScroll={false}
            closeOnClickOutside={true}
            onClose={closeImageViewer}
          />
        )}
      </div>
    </>
  );
};

export default Template4CollectionContainer;
