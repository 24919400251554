import React, { useState } from "react";
import { Template4Header } from "../Template4Header";
import { Template4Footer } from "../Template4Footer";
import { Template4ProductContent } from "./Template4ProductContent";
import { useProductEnquiry } from "../../../../../utils/hooks";
import { Template4Video } from "../Video4/Template4Video";
import Template4VideoPlayer from "./Template4ProductVideoPlayer";
import Template4ProductModelViewer from "./Template4ProductModelViewer";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

export const Template4Product = ({data,setFeatures , settings, goBack}) => {
  const [enquiry,setEnquiry] = useState(false);
  const {ProductEnquiryDesign} = useProductEnquiry({apiPayload: {
    request_made_by_user_id: 0,
    request_made_to_exhibitor_id: data?.product[0]?.exhibitor_id,
    product_id: data?.product[0]?.id,
    enquiry_type: 'product',
    source: 'micropages',
  },isActive:enquiry,setChange:setEnquiry})
  
  return (
    <>
    <div className="page">
    <Template4Header logoURL={data?.logo} setFeatures={setFeatures} settings={settings} goBack={goBack}/>
    <Template4VideoPlayer data={data?.product_videos}/>
    <Template4ProductModelViewer data={data?.product_models}/>
    <Template4ProductContent productImage={data?.product?.[0]?.product_image} productPrice={data?.product?.[0]?.saleprice} productSKU={data?.product?.[0]?.product_sku} productTitle={data?.product?.[0]?.product_title} productDesc={data?.product?.[0]?.product_description} setEnquiry={setEnquiry} data={data} buyNowURL={data?.product?.[0]?.buynowurl} productModel={data?.product?.[0]?.product_model} currencyCode={data?.product?.[0]?.currency_code} ettings={settings}/>
    
    <ProductEnquiryDesign/>
    
    </div>
    </>
  );
};


